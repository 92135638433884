import React from "react";

const Intro = () => {
  const introContent = {
    name: "Eduardo Sesma",
    designation: "Software Developer / Cloud Engineer",
    text: (
      <>
        <p>
          Hi, I'm Eduardo Sesma. With two years dedicated to cloud engineering,
          I've honed my skills in designing scalable cloud solutions primarily
          using AWS. I thrive in collaborative settings, working alongside teams
          to tailor efficient and scalable solutions to meet business needs.
          Passionate about overcoming real-world challenges with cloud
          technologies, I'm keen to bring innovative solutions to your team.
        </p>
      </>
    ),
  };

  return (
    <>
      <div className="about_title">
        <h3>{introContent.name}</h3>
        <span>{introContent.designation}</span>
      </div>
      <div className="about_text">{introContent.text}</div>
    </>
  );
};

export default Intro;
